import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { SectionTilesTypesProps } from '../../../utils/SectionProps';
import SectionHeader, { SectionHeaderTag } from '../partials/SectionHeader';
import { getData } from '../../../api/cmsAPI';
import { apiRequestEndpoint, requestFilterEndpoint } from '../../../utils/endpoints';
import { CareerOffer } from '../../../types/CareerOffer';
import CareerOffers from '../career-offers/CareerOffers';
import Button from '../../elements/Button';

interface CareerProps extends SectionTilesTypesProps {
  className?: string;
}

const defaultProps: CareerProps = {
  topOuterDivider: false,
  bottomOuterDivider: false,
  topDivider: false,
  bottomDivider: false,
  hasBgColor: false,
  invertColor: false,
  pushLeft: false,
};

const Career: React.FC<CareerProps> = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'career section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const [data, setData] = useState<CareerOffer[]>();
  const [isError, setError] = useState<boolean>(false);

  const fetchDataFromCMS = useCallback(() => {
    setError(false);
    getData(apiRequestEndpoint + requestFilterEndpoint)
      .then(response => {
        setData(response.data);
      })
      .catch(() => { setError(true); });
  }, []);

  useEffect(() => {
    fetchDataFromCMS();
  }, []);

  return (
    <section id="career-section" {...props} className={outerClasses}>
      <div className={innerClasses}>
        <div className="container">
          <SectionHeader tag={SectionHeaderTag.H1} title="Career" className="center-content">
            <h2 className="h4">Are you interested in joining our team?</h2>
            <p className="mb-0">Have a look!</p>
          </SectionHeader>
          {isError ? (
            <div className="center-content">
              <p>No data found</p>
              <div>
                <Button onClick={fetchDataFromCMS} color="primary" wideMobile>
                  Try again
                </Button>
              </div>
            </div>
          ) : (
            <CareerOffers careerOffer={data} />
          )}
        </div>
      </div>
    </section>
  );
};

Career.defaultProps = defaultProps;

export default Career;
