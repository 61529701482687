import React from 'react';
import LayoutDefault from '../../layouts/LayoutDefault';
import PageMetaData from '../../components/layout/PageMetaData';
import Career from '../../components/sections/career/Career';

const CareerPage: React.FC = () => (
  <LayoutDefault>
    <PageMetaData title="Career" />
    <Career />
  </LayoutDefault>
);

export default CareerPage;
