import React, { Children, PropsWithChildren } from 'react';
import classNames from 'classnames';

export enum SectionHeaderTag {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
}

interface SectionHeaderProps {
  className?: string;
  title: string;
  tag?: SectionHeaderTag;
}

const defaultProps: PropsWithChildren<SectionHeaderProps> = {
  children: null,
  title: '',
  tag: SectionHeaderTag.H2,
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ className, title, children, tag, ...props }) => {
  const classes = classNames('section-header', className);
  const hasChildren = Children.toArray(children).filter(Boolean).length > 0;

  // eslint-disable-next-line no-undef
  const Component = tag as keyof JSX.IntrinsicElements;

  return (
    <>
      {title && (
        <div {...props} className={classes}>
          <Component className={classNames('mt-0', !hasChildren && 'mb-0')}>{title}</Component>
          {children}
        </div>
      )}
    </>
  );
};

SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
