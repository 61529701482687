import React from 'react';
import classNames from 'classnames';

interface ButtonProps {
  className?: string;
  tag?: string;
  color?: string;
  size?: string;
  loading?: boolean;
  wide?: boolean;
  wideMobile?: boolean;
  disabled?: boolean;
  href?: string;
  target?: string;
  onClick?: () => void;
}

const defaultProps: ButtonProps = {
  tag: 'button',
  color: '',
  size: '',
  loading: false,
  wide: false,
  wideMobile: false,
  disabled: false,
};

const Button: React.FC<ButtonProps> = ({
  className,
  tag,
  color,
  size,
  loading,
  wide,
  wideMobile,
  disabled,
  ...props
}: ButtonProps) => {
  const classes = classNames(
    'button',
    color && `button-${color}`,
    size && `button-${size}`,
    loading && 'is-loading',
    wide && 'button-block',
    wideMobile && 'button-wide-mobile',
    className,
  );

  // eslint-disable-next-line no-undef
  const Component = tag as keyof JSX.IntrinsicElements;
  return <Component {...props} className={classes} disabled={disabled} />;
};

Button.defaultProps = defaultProps;

export default Button;
