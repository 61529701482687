import { Card } from 'antd';
import React from 'react';
import { CareerOffer } from '../../types/CareerOffer';
import Button from './Button';

interface Props {
  careerOffer?: CareerOffer;
}

const { Meta } = Card;

const CareerOfferCard = ({ careerOffer }: Props) => {
  return (
    <Card
      className="center-content career-card"
      title={careerOffer?.title}
      bordered={false}
      key={careerOffer?.id}
      cover={
        <img
          alt={careerOffer?.title}
          src={`${process.env.REACT_APP_CMS_BASE_URL}/assets/${careerOffer?.image.filename_disk}`}
        />
      }
    >
      <Meta />
      <Button color="primary" wideMobile tag={'a'} href={`${process.env.REACT_APP_REDIRECT_URL}${careerOffer?.slug}`}>
        Click me
      </Button>
    </Card>
  );
};

export default CareerOfferCard;
