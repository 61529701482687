import React from 'react';
import { Spin, Col, Row } from 'antd';
import { CareerOffer } from '../../../types/CareerOffer';
import CareerOfferCard from '../../elements/CareerOfferCard';

interface Props {
  careerOffer?: CareerOffer[];
}

const CareerOffers = ({ careerOffer }: Props): JSX.Element => {
  return (
    <>
      {careerOffer ? (
        <Row gutter={[24, 24]}>
          {careerOffer.map((item: CareerOffer, id) => (
            <Col key={id} span={24} sm={12} md={8}>
              <CareerOfferCard careerOffer={item} />
            </Col>
          ))}
        </Row>
      ) : (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      )}
    </>
  );
};

export default CareerOffers;
